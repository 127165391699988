import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

interface CreateEvaluationRequest {
    uuid: string | undefined
    result: any
}

export const evaluationsApi = createApi({
    reducerPath: 'evaluationsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_SERVICES_URL,
    }),
    endpoints: (builder) => ({
        getEvaluation: builder.query<any, string | undefined>({
            query: (uuid: string) => `evaluations/${uuid}`,
        }),
        createEvaluationResult: builder.mutation<void, CreateEvaluationRequest>({
            query: (args: CreateEvaluationRequest) => ({
                url: `evaluations/${args.uuid}/result`,
                method: 'POST',
                body: { result: args.result },
            }),
        }),
    }),
})

export const { useGetEvaluationQuery, useCreateEvaluationResultMutation } = evaluationsApi
