import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { store } from './app/store'
import { reportWebVitals } from './reportWebVitals'
import { App } from './App'
import { Fallback } from './Fallback'

import './scss/index.scss'

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
    <StrictMode>
        <Provider store={store}>
            <ToastContainer />
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Routes>
                    <Route path="/evaluations/:uuid?" element={<App />} />
                    <Route path="*" element={<Fallback />} />
                </Routes>
            </BrowserRouter>
        </Provider>
    </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
