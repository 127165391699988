const Render = ({ text, tag }) => {
    const Tag = tag
    return <Tag className="text-break">{text}</Tag>
}

export default {
    type: 'title',
    label: 'Titre',
    default: {
        identifier: '',
        required: false,
        text: 'Titre par défaut',
        tag: 'h1',
    },
    Render,
}
