import { configureStore } from '@reduxjs/toolkit'
import type { ThunkAction, Action } from '@reduxjs/toolkit'

import { evaluationsApi } from '../services/evaluations'
import { rtkQueryErrorLogger } from './rtkQueryErrorLogger'

export const store = configureStore({
    reducer: {
        [evaluationsApi.reducerPath]: evaluationsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([evaluationsApi.middleware, rtkQueryErrorLogger]),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
