import { Form } from 'react-bootstrap'

const Render = ({ identifier, text, notes, onUpdate }) => {
    return (
        <>
            <p className="text-break" dangerouslySetInnerHTML={{ __html: text.replaceAll('\n', '<br>') }}></p>
            <div className="d-flex flex-column gap-2">
                {notes.map((note, index) => (
                    <Form.Check
                        key={index}
                        type="radio"
                        name={identifier}
                        label={note}
                        value={note}
                        onChange={(e) => onUpdate(identifier, e.target.value)}
                    />
                ))}
            </div>
        </>
    )
}

export default {
    type: 'notes',
    label: 'Notes',
    default: {
        identifier: '',
        required: true,
        text: 'Question par défaut',
        notes: [],
    },
    Render,
}
