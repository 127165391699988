import Notes from './Notes'
import Paragraph from './Paragraph'
import Remark from './Remark'
import Title from './Title'

const blocks = [Title, Paragraph, Notes, Remark].reduce(
    (acc, component) => ({
        ...acc,
        [component.type]: component,
    }),
    {}
)

export const Block = {
    Render: (props) => {
        const Block = blocks[props.type]
        return (
            <div className={`block render ${props.type}`}>
                <Block.Render {...props} />
            </div>
        )
    },
}
