import { Form } from 'react-bootstrap'

const Render = ({ identifier, text, onUpdate }) => (
    <Form.Group>
        <Form.Label
            className="text-break"
            dangerouslySetInnerHTML={{ __html: text.replaceAll('\n', '<br>') }}
        ></Form.Label>
        <Form.Control key="text" as="textarea" onChange={(e) => onUpdate(identifier, e.target.value)} />
    </Form.Group>
)

export default {
    type: 'remark',
    label: 'Remarque',
    default: {
        identifier: '',
        required: true,
        text: 'Texte par défaut',
    },
    Render,
}
