import { useState } from 'react'
import { Container, Button } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { Block } from './components/blocks/Block'
import { useGetEvaluationQuery, useCreateEvaluationResultMutation } from './services/evaluations'
import { toast } from 'react-toastify'

export function App() {
    const params = useParams()

    const [result, setResult] = useState<any>({})
    const [errors, setErrors] = useState<any>({})
    const [submitted, setSubmitted] = useState<boolean>(false)

    const { data: evaluation, isFetching: isFetchingEvaluation, isError } = useGetEvaluationQuery(params.uuid)
    const [createResult, { isLoading: isCreatingResult }] = useCreateEvaluationResultMutation()

    const onSubmit = async () => {
        // Check if fields are filled
        const errors = evaluation.struct.filter((block: any) => block.required && !result[block.identifier])
        setErrors(errors.reduce((acc: any, block: any) => ({ ...acc, [block.identifier]: true }), {}))
        if (errors.length > 0) {
            toast.error('Certains champs obligatoires sont manquants.')
            return
        }

        const response: any = await createResult({
            uuid: params.uuid,
            result,
        })
        if (response.error) return

        toast.success(response.data.message)
        setSubmitted(true)
    }

    return (
        <Container className="py-5">
            {isFetchingEvaluation || isError ? (
                <div className="position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center">
                    <div className="d-flex flex-column align-items-center">
                        {isError ? (
                            <h4>Cette évaluation n'est pas ou plus accessible.</h4>
                        ) : (
                            <>
                                <Spinner animation="grow" className="mb-4" />
                                <h4>Chargement</h4>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    <h1 className="mb-5">{evaluation.sessionName}</h1>
                    {evaluation.struct.map((block: any, i: number) => (
                        <div key={i}>
                            {errors[block.identifier] && (
                                <div className="text-danger">Cette question doit être remplie.</div>
                            )}
                            <Block.Render
                                onUpdate={(key: string, value: any) =>
                                    setResult({
                                        ...result,
                                        [key]: value,
                                    })
                                }
                                {...block}
                            />
                        </div>
                    ))}

                    <Button variant="primary" onClick={onSubmit} disabled={submitted || isCreatingResult}>
                        {submitted ? 'Évaluation envoyée' : isCreatingResult ? 'Envoi en cours' : 'Envoyer résultat'}
                    </Button>
                </>
            )}
        </Container>
    )
}
