const Render = ({ text }) => (
    <p className="text-break" dangerouslySetInnerHTML={{ __html: text.replaceAll('\n', '<br>') }}></p>
)

export default {
    type: 'paragraph',
    label: 'Paragraph',
    default: {
        identifier: '',
        required: false,
        text: 'Texte par défaut',
    },
    Render,
}
